.about { 
    display: inline-block;
    width: 100%;
    padding: 39px 0 40px;
	.about-holder { 
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .text { 
            float: left;
            width: 40%;
            .text-holder { 
                display: inline-block;
                p { 

                    strong { 

                    }
                }
            }
        }

        .images { 
            float: right;
            width: 42.5%;
            .images-holder { 
                display: inline-block;
                width: 100%;
                .image-accordion-three-way { 
                    display: inline-block;
                    width: 100%;
                    .image-accordion-three-way-holder { 
                        display: flex;
                        width: calc(100% + 5px);
                        margin-left: -2.5px;
                        &:hover {
                            .image { 
                                width: 16.8%;
                                transition: all 500ms;
                            }
                        }
                        .image { 
                            float: left;
                            width: 33.333%;
                            box-sizing: border-box;
                            padding: 0 2.5px;
                            transition: all 500ms;
                            &:hover {
                                width: 66.4%;
                                transition: all 500ms;
                            }
                            &.left {
                                .image-container { 
                                    img {
                                        object-position: left;
                                    }
                                }
                            }
                            &.right {
                                .image-container { 
                                    img {
                                        object-position: right;
                                    }
                                }
                            }
                            .image-container { 
                                display: inline-block;
                                width: 100%;
                                height: 580px;
                                img { 
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/about";
@import "../../media/mobile/includes/index/about";
