@media (max-width: $screen-sm) {
    // sm-view
    .assorrtment-gallery { 
        padding: 3px 0 56px;
        .assorrtment-gallery-holder { 
            .heading {
                margin: 0 0 24px;
                >* {
                    font-weight: 400;
                    line-height: 131%;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
