.about {
  display: inline-block;
  width: 100%;
  padding: 39px 0 40px;
}
.about .about-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.about .about-holder .text {
  float: left;
  width: 40%;
}
.about .about-holder .text .text-holder {
  display: inline-block;
}
.about .about-holder .images {
  float: right;
  width: 42.5%;
}
.about .about-holder .images .images-holder {
  display: inline-block;
  width: 100%;
}
.about .about-holder .images .images-holder .image-accordion-three-way {
  display: inline-block;
  width: 100%;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder {
  display: flex;
  width: calc(100% + 5px);
  margin-left: -2.5px;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder:hover .image {
  width: 16.8%;
  transition: all 500ms;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image {
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 2.5px;
  transition: all 500ms;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image:hover {
  width: 66.4%;
  transition: all 500ms;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image.left .image-container img {
  object-position: left;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image.right .image-container img {
  object-position: right;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image .image-container {
  display: inline-block;
  width: 100%;
  height: 580px;
}
.about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1479px) {
  .about .about-holder .text {
    width: 31%;
  }
  .about .about-holder .images {
    width: 56.3%;
  }
}
@media (max-width: 1024px) {
  .about {
    padding: 25px 0 25px;
  }
  .about .about-holder {
    flex-wrap: wrap;
  }
  .about .about-holder .text {
    width: 100%;
    margin: 0 0 22px;
  }
  .about .about-holder .images {
    width: 100%;
  }
  .about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image .image-container {
    height: 42vw;
  }
}
@media (max-width: 767px) {
  .about {
    padding: 34px 0 25px;
  }
  .about .about-holder .text {
    margin: 0 0 21px;
  }
  .about .about-holder .images .images-holder .image-accordion-three-way .image-accordion-three-way-holder .image .image-container {
    height: 100vw;
  }
}
.assorrtment-gallery {
  display: inline-block;
  width: 100%;
  padding: 55px 0 179px;
}
.assorrtment-gallery .assorrtment-gallery-holder {
  display: inline-block;
  width: 100%;
}
.assorrtment-gallery .assorrtment-gallery-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.assorrtment-gallery .assorrtment-gallery-holder .heading > * {
  margin: 0;
}
.assorrtment-gallery .assorrtment-gallery-holder .image-accordion {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder {
  display: flex;
  width: calc(100% + 5px);
  margin-left: -2.5px;
}
.assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder .image {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2.5px;
  transition: all 500ms;
}
.assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder .image:hover {
  width: 310%;
}
.assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder .image .image-container {
  display: inline-block;
  width: 100%;
  height: 600px;
}
.assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder .image .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1479px) {
  .assorrtment-gallery {
    padding: 4px 0 98px;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .heading {
    margin: 0 0 49px;
  }
}
@media (max-width: 1024px) {
  .assorrtment-gallery {
    padding: 4px 0 54px;
    overflow: hidden;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .heading {
    margin: 0 0 26px;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .image-accordion {
    overflow: visible;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .image-accordion .slick-slider .slick-list {
    overflow: visible;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder > *:not(:first-of-type) {
    display: none !important;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder.slick-initialized > *:not(:first-of-type) {
    display: block;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder {
    display: block;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .image-accordion .image-accordion-holder .image .image-container {
    width: 260px;
    height: 290px;
  }
}
@media (max-width: 767px) {
  .assorrtment-gallery {
    padding: 3px 0 56px;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .heading {
    margin: 0 0 24px;
  }
  .assorrtment-gallery .assorrtment-gallery-holder .heading > * {
    font-weight: 400;
    line-height: 131%;
  }
}
.company-points {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 95px 0 92px;
}
.company-points .company-points-holder {
  display: inline-block;
  width: 100%;
}
.company-points .company-points-holder .heading {
  display: inline-block;
  width: 100%;
}
.company-points .company-points-holder .points-list {
  float: left;
  width: 100%;
  position: relative;
}
.company-points .company-points-holder .points-list .points-list-holde {
  float: left;
  width: 100%;
}
.company-points .company-points-holder .points-list .points-list-holde .main-image {
  width: 42.5%;
  height: 100%;
  position: absolute;
  left: 57.5%;
  top: 0;
}
.company-points .company-points-holder .points-list .points-list-holde .main-image .main-image-holder {
  display: inline-block;
  width: 135%;
  height: 100%;
}
.company-points .company-points-holder .points-list .points-list-holde .main-image .main-image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.company-points .company-points-holder .points-list .points-list-holde ul {
  float: left;
  width: 100%;
  margin: 0;
}
.company-points .company-points-holder .points-list .points-list-holde ul li {
  float: left;
  width: 100%;
}
.company-points .company-points-holder .points-list .points-list-holde ul li:last-of-type .name {
  border-bottom: 1px solid transparent;
}
.company-points .company-points-holder .points-list .points-list-holde ul li:hover .name {
  background: black;
  color: white;
  transition: all 300ms;
}
.company-points .company-points-holder .points-list .points-list-holde ul li:hover .image .image-holder img {
  opacity: 1;
  transition: all 300ms;
}
.company-points .company-points-holder .points-list .points-list-holde ul li .name {
  display: inline-block;
  width: 130.15%;
  padding: 50px 50px 50px 15.075%;
  box-sizing: border-box;
  background: #F5F5F5;
  margin-left: -15.075%;
  border-bottom: 1px solid #EAEAEA;
  transition: all 300ms;
}
.company-points .company-points-holder .points-list .points-list-holde ul li .name .text {
  display: flex;
  height: 133px;
  align-items: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.company-points .company-points-holder .points-list .points-list-holde ul li .image {
  width: 42.5%;
  height: 100%;
  position: absolute;
  left: 57.5%;
  top: 0;
}
.company-points .company-points-holder .points-list .points-list-holde ul li .image .image-holder {
  display: inline-block;
  width: 135%;
  height: 100%;
}
.company-points .company-points-holder .points-list .points-list-holde ul li .image .image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: all 300ms;
}

@media (max-width: 1479px) {
  .company-points {
    padding: 48px 0 46px;
  }
  .company-points .company-points-holder .heading > * {
    margin: 0 0 49px;
  }
  .company-points .company-points-holder .points-list .points-list-holde .main-image {
    width: 50%;
    left: 50%;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .image {
    width: 50%;
    left: 50%;
  }
}
@media (max-width: 1024px) {
  .company-points {
    padding: 28px 0 28px;
  }
  .company-points .company-points-holder .heading > * {
    margin: 0 0 23px;
  }
  .company-points .company-points-holder .points-list .points-list-holde .main-image {
    width: 50%;
    left: 56.8%;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .name {
    width: 110%;
    padding: 25px 24px 25px 5%;
    margin-left: -5%;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .name .text {
    height: 46px;
    font-size: 14px;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .image {
    width: 50%;
    left: 56.8%;
  }
}
@media (max-width: 767px) {
  .company-points .company-points-holder .heading > * {
    margin: 0 0 25px;
  }
  .company-points .company-points-holder .points-list {
    padding: 0 0 100vw;
  }
  .company-points .company-points-holder .points-list .points-list-holde .main-image {
    left: 50%;
    width: 110.35%;
    height: 100vw;
    transform: translateX(-50%);
    bottom: 0;
    top: auto;
  }
  .company-points .company-points-holder .points-list .points-list-holde .main-image .main-image-holder {
    width: 100%;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .name {
    width: 110.35%;
    margin-left: -5.175%;
    padding: 8px 5.175%;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .name .text {
    height: 43px;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .image {
    left: 50%;
    width: 110.35%;
    height: 100vw;
    transform: translateX(-50%);
    bottom: 0;
    top: auto;
  }
  .company-points .company-points-holder .points-list .points-list-holde ul li .image .image-holder {
    width: 100%;
  }
}
.company-pros {
  display: inline-block;
  width: 100%;
  padding: 37px 0 35px;
}
.company-pros .company-pros-holder {
  display: inline-block;
  width: 100%;
}
.company-pros .company-pros-holder > .heading {
  display: inline-block;
  width: 100%;
}
.company-pros .company-pros-holder .pros {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.company-pros .company-pros-holder .pros .pro {
  width: 33.333%;
  height: auto;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 28px;
}
.company-pros .company-pros-holder .pros .pro .pro-holder {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.company-pros .company-pros-holder .pros .pro .pro-holder > .heading {
  display: inline-block;
  width: 100%;
  min-height: 56px;
  margin: 0 0 15px;
}
.company-pros .company-pros-holder .pros .pro .pro-holder > .heading h3, .company-pros .company-pros-holder .pros .pro .pro-holder > .heading .h3 {
  margin: 0;
  line-height: normal;
}
.company-pros .company-pros-holder .pros .pro .pro-holder .text {
  display: inline-block;
  width: 100%;
}
.company-pros .company-pros-holder .pros .pro .pro-holder .text p {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #434343;
  margin: 0 0 16px;
}

@media (max-width: 1479px) {
  .company-pros .company-pros-holder > .heading > * {
    margin: 0 0 51px;
  }
  .company-pros .company-pros-holder .pros {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .company-pros .company-pros-holder .pros .pro {
    padding: 0 10px;
  }
  .company-pros .company-pros-holder .pros .pro .pro-holder > .heading {
    min-height: 86px;
  }
}
@media (max-width: 1024px) {
  .company-pros {
    padding: 24px 0 30px;
  }
  .company-pros .company-pros-holder > .heading > * {
    margin: 0 0 23px;
  }
  .company-pros .company-pros-holder .pros {
    width: 100%;
    margin: 0;
  }
  .company-pros .company-pros-holder .pros .pro {
    width: 100%;
    padding: 0;
    margin: 0 0 9px;
  }
  .company-pros .company-pros-holder .pros .pro .pro-holder > .heading {
    min-height: auto;
    margin: 0 0 17px;
  }
  .company-pros .company-pros-holder .pros .pro .pro-holder .text p {
    font-size: 12px;
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .company-pros {
    padding: 24px 0 17px;
  }
  .company-pros .company-pros-holder .pros .pro {
    margin: 0 0 20px;
  }
  .company-pros .company-pros-holder .pros .pro .pro-holder > .heading {
    margin: 0 0 11px;
  }
}