@media (max-width: $screen-lg) {
    // lg-md view
    .about { 
        .about-holder { 
            .text {
                width: 31%;
            }

            .images {
                width: 56.3%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .about { 
        padding: 25px 0 25px;
        .about-holder { 
            flex-wrap: wrap;
            .text {
                width: 100%;
                margin: 0 0 22px;
            }

            .images {
                width: 100%;
                .images-holder {
                    .image-accordion-three-way {
                        .image-accordion-three-way-holder {
                            .image {
                                .image-container {
                                    height: 42vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
