@media (max-width: $screen-sm) {
    // sm-view
    .company-points {
        .company-points-holder { 
            .heading { 
                >* {
                    margin: 0 0 25px;
                }
            }
            .points-list { 
                padding:  0 0 100vw;
                .points-list-holde { 
                    .main-image { 
                        left: 50%;
                        width: 110.35%;
                        height: 100vw;
                        transform: translateX(-50%);
                        bottom: 0;
                        top: auto;
                        .main-image-holder {
                            width: 100%;
                        }
                    }

                    ul {
                        li {
                            .name { 
                                width: 110.35%;
                                margin-left: -5.175%;
                                padding: 8px 5.175%;
                                .text {
                                    height: 43px;
                                }
                            }
                            .image { 
                                left: 50%;
                                width: 110.35%;
                                height: 100vw;
                                transform: translateX(-50%);
                                bottom: 0;
                                top: auto;
                                .image-holder { 
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
