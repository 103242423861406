.company-pros { 
    display: inline-block;
    width: 100%;
    padding: 37px 0 35px;
	.company-pros-holder { 
        display: inline-block;
        width: 100%;
        >.heading { 
            display: inline-block;
            width: 100%;
            h2, .h2 { 

            }
        }

        .pros { 
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
            .pro { 
                width: 33.333%;
                height: auto;
                box-sizing: border-box;
                padding: 0 15px;
                margin: 0 0 28px;
                .pro-holder { 
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    >.heading { 
                        display: inline-block;
                        width: 100%;
                        min-height: 56px;
                        margin: 0 0 15px;
                        h3, .h3 { 
                            margin: 0;
                            line-height: normal;
                        }
                    }

                    .text { 
                        display: inline-block;
                        width: 100%;
                        p { 
                            font-family: Open Sans;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            color: $gray;
                            margin: 0 0 16px;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/company_pros";
@import "../../media/mobile/includes/index/company_pros";
