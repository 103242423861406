@media (max-width: $screen-lg) {
    // lg-md view
    .assorrtment-gallery { 
        padding: 4px 0 98px;
        .assorrtment-gallery-holder { 
            .heading {
                margin: 0 0 49px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .assorrtment-gallery { 
        padding: 4px 0 54px;
        overflow: hidden;
        .assorrtment-gallery-holder { 
            .heading {
                margin: 0 0 26px;
            }

            .image-accordion { 
                overflow: visible;
                .slick-slider {
                    .slick-list {
                        overflow: visible;
                        .slick-track {
                            .slick-slide {
                               
                            }
                        }
                    }
                }

                .image-accordion-holder {
                    >*:not(:first-of-type) {
                        display: none !important;
                    }
                }
                .image-accordion-holder.slick-initialized {
                    >*:not(:first-of-type) {
                        display: block;
                    }
                }

                .image-accordion-holder { 
                    display: block;
                    .image { 
                        .image-container { 
                            width: 260px;
                            height: 290px;
                        }
                    }
                }
            }
        }
    }
}
