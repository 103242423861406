@media (max-width: $screen-sm) {
    // sm-view
    .company-pros { 
        padding: 24px 0 17px;
        .company-pros-holder { 
            .pros { 
                .pro { 
                    margin: 0 0 20px;
                    .pro-holder { 
                        >.heading { 
                            margin: 0 0 11px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
