.assorrtment-gallery { 
    display: inline-block;
    width: 100%;
    padding: 55px 0 179px;
	.assorrtment-gallery-holder { 
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            >* {
                margin: 0;
            }
        }
        .image-accordion { 
            display: inline-block;
            width: 100%;
            overflow: hidden;
            .image-accordion-holder { 
                display: flex;
                width: calc(100% + 5px);
                margin-left: -2.5px;
                .image { 
                    float: left;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 2.5px;
                    transition: all 500ms;
                    &:hover {
                        width: 310%;
                    }
                    .image-container { 
                        display: inline-block;
                        width: 100%;
                        height: 600px;
                        img { 
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/assortment";
@import "../../media/mobile/includes/index/assortment";
