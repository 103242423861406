@media (max-width: $screen-lg) {
    // lg-md view
    .company-points { 
        padding: 48px 0 46px;
        .company-points-holder { 
            .heading {
                >* {
                    margin: 0 0 49px;
                }
            }

            .points-list { 
                .points-list-holde { 
                    .main-image { 
                        width: 50%;
                        left: 50%;
                    }

                    ul { 
                        li {
                            .image {
                                width: 50%;
                                left: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .company-points {
        padding: 28px 0 28px;
        .company-points-holder { 
            .heading {
                >* {
                    margin: 0 0 23px;
                }
            }

            .points-list { 
                .points-list-holde { 
                    .main-image { 
                        width: 50%;
                        left: 56.8%;
                    }

                    ul { 
                        li {
                            .name { 
                                width: 110%;
                                padding: 25px 24px 25px 5%;
                                margin-left: -5%;
                                .text { 
                                    height: 46px;
                                    font-size: 14px;
                                }
                            }
                            .image {
                                width: 50%;
                                left: 56.8%;
                            }
                        }
                    }
                }
            }
        }
    }
}
