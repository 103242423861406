@media (max-width: $screen-lg) {
    // lg-md view
    .company-pros { 
        .company-pros-holder { 
            >.heading {
                >* {
                    margin: 0 0 51px;
                }
            }

            .pros { 
                width: calc(100% + 20px);
                margin-left: -10px;
                .pro {
                    padding: 0 10px;
                    .pro-holder { 
                        >.heading {
                            min-height: 86px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .company-pros { 
        padding: 24px 0 30px;
        .company-pros-holder { 
            >.heading { 
                >* {
                    margin: 0 0 23px;
                }
            }

            .pros { 
                width: 100%;
                margin: 0;
                .pro { 
                    width: 100%;
                    padding: 0;
                    margin: 0 0 9px;
                    .pro-holder { 
                        >.heading { 
                            min-height: auto;
                            margin: 0 0 17px;
                        }

                        .text {
                            p {
                                font-size: 12px;
                                margin:  0 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
