@media (max-width: $screen-sm) {
    // sm-view
    .about { 
        padding: 34px 0 25px;
        .about-holder { 
            .text {
                margin: 0 0 21px;
            }

            .images { 
                .images-holder { 
                    .image-accordion-three-way { 
                        .image-accordion-three-way-holder { 
                            .image { 
                                .image-container { 
                                    height: 100vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
