.company-points { 
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding: 95px 0 92px;
    .company-points-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
        }

        .points-list { 
            float: left;
            width: 100%;
            position: relative;
            .points-list-holde { 
                float: left;
                width: 100%;
                .main-image { 
                    width: 42.5%;
                    height: 100%;
                    position: absolute;
                    left: 57.5%;
                    top: 0;
                    .main-image-holder {
                        display: inline-block;
                        width: 135%;
                        height: 100%;
                        img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                }

                ul { 
                    float: left;
                    width: 100%;
                    margin: 0;
                    li { 
                        float: left;
                        width: 100%;
                        &:last-of-type {
                            .name { 
                                border-bottom: 1px solid transparent;
                            }
                        }
                        &:hover {
                            .name { 
                                background: black;
                                color: white;
                                transition: all 300ms;
                            }

                            .image { 
                                .image-holder { 
                                    img {
                                        opacity: 1;
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }
                        .name { 
                            display: inline-block;
                            width: 130.15%;
                            padding: 50px 50px 50px 15.075%;
                            box-sizing: border-box;
                            background: #F5F5F5;
                            margin-left: -15.075%;
                            border-bottom: 1px solid #EAEAEA;
                            transition: all 300ms;
                            .text {
                                display: flex;
                                height: 133px;
                                align-items: center;
                                font-size: 28px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }

                        .image { 
                            width: 42.5%;
                            height: 100%;
                            position: absolute;
                            left: 57.5%;
                            top: 0;
                            .image-holder { 
                                display: inline-block;
                                width: 135%;
                                height: 100%;
                                img { 
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    opacity: 0;
                                    transition: all 300ms;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/company_points";
@import "../../media/mobile/includes/index/company_points";
